import api from "@/base/utils/request";

// 获取模板详情
export const configDetail = data => {
  return api({
    url: "/admin/blessing/tmpl/detail",
    method: "post",
    data
  });
};

// 保存模板详情
export const saveConfig = data => {
  return api({
    url: "/admin/blessing/tmpl/save",
    method: "post",
    data
  });
};
