<template>
  <div class="brand-content" v-loading="formLoading">
    <el-form v-if="Form" size="medium" :model="Form" :rules="rules" label-position="right" label-width="80px"
      class="small-form" ref="Form" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">基础信息</p>
        <div class="modular-content">
          <el-form-item label="模板名称：" prop="name">
            <el-input v-model="Form.name" placeholder="请输入模板名称"></el-input>
          </el-form-item>
          <el-form-item label="模板分类：" prop="category_id">
            <el-select v-model="Form.category_id" placeholder="请选择模板分类">
              <el-option v-for="(item,index) in categoryArr" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <!-- <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>选择前，需要先添加模版分类</span>
        </div> -->
          </el-form-item>
          <el-form-item label="封面图：" prop="cover">
            <image-wall v-model="Form.cover" :width="160" :height="90" ratio="320:180">
              <p slot="info" class="img-tips">建议上传尺寸比例为960*540</p>
            </image-wall>
            <el-input v-show="false" v-model="Form.cover"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="modular" style="padding-top: 20px;">
        <p class="modular-title">推送渠道</p>
        <div class="modular-content">
          <el-form-item label-width="0px" label="" prop="channel_msg_config">
            <el-form size="medium" :model="Form.channel_msg_config" :rules="systemMsgRules"
              label-position="right" label-width="80px" class="small-form nest-form" ref="systemMsg"
              @submit.native.prevent>
              <el-form-item label="系统消息：" prop="content">
                <el-input type="textarea" resize="none" :maxlength="40" show-word-limit :rows="4"
                  v-model="Form.channel_msg_config.content" placeholder="请输入系统消息"></el-input>
              </el-form-item>
            </el-form>
          </el-form-item>
          <el-form-item label="海报弹窗：" prop="channel_poster_enable">
            <el-switch :active-value="1" :inactive-value="0" v-model="Form.channel_poster_enable"></el-switch>
          </el-form-item>
          <el-form-item v-if="Form.channel_poster_enable" label-width="0px" label=""
            prop="channel_poster_config">
            <el-form size="medium" :model="Form.channel_poster_config" :rules="posterRules"
              label-position="right" label-width="80px" class="small-form nest-form" ref="posterConfig"
              @submit.native.prevent>
              <div class="inner-form">
                <el-form-item label="祝福类型：" prop="type">
                  <el-radio v-model="Form.channel_poster_config.type" label="image">图片</el-radio>
                  <el-radio v-model="Form.channel_poster_config.type" label="video">视频</el-radio>
                </el-form-item>
                <el-form-item v-if="Form.channel_poster_config.type === 'image'" label="祝贺图："
                  prop="res_image">
                  <DefaultImageSelector v-model="Form.channel_poster_config.res_image"
                    :DefaultImage="DefaultPoster" :width="300" :height="170" ratio="600:340">
                    <p slot="info" class="img-tips">建议上传尺寸比例为600*340</p>
                  </DefaultImageSelector>
                </el-form-item>
                <el-form-item v-if="Form.channel_poster_config.type === 'video'" label="祝贺视频："
                  prop="res_video">
                  <image-wall v-model="Form.channel_poster_config.res_video" addText="上传视频" :width="160"
                    :height="90" UploadType="video">
                    <p slot="info" class="img-tips">推荐mp4格式，大小200m以内，屏幕比例为16:9的横屏视频</p>
                  </image-wall>
                </el-form-item>
                <el-form-item label="背景图：" prop="background">
                  <DefaultImageSelector v-model="Form.channel_poster_config.background"
                    :DefaultImage="DefaultBackground" :width="300" :height="414" ratio="600:828">
                    <p slot="info" class="img-tips">建议上传尺寸比例为600*828</p>
                  </DefaultImageSelector>
                </el-form-item>
                <el-form-item label="祝福语：" prop="content">
                  <el-input type="textarea" resize="none" :maxlength="150" show-word-limit :rows="8"
                    v-model="Form.channel_poster_config.content" placeholder="请输入祝福语"></el-input>
                </el-form-item>
                <el-form-item label="祝福署名：" prop="sign_name">
                  <el-input v-model="Form.channel_poster_config.sign_name" placeholder="请输入祝福署名"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="poster-preview" v-if="Form.channel_poster_enable">
      <el-image class="potser-bg" :style="{
        backgroundColor: Form.channel_poster_config.background?'':'#faebd6'
      }" :src="Form.channel_poster_config.background" fit="cover"></el-image>
      <div class="preview-title">祝福预览</div>
      <div class="poster-content">
        <div class="poster-media">
          <el-image v-if="Form.channel_poster_config.type === 'image'" class="media-bg" :src="res_media"
            fit="cover"></el-image>
          <div class="video-mask" v-if="Form.channel_poster_config.type === 'video'">
            <el-image class="video-play" :src="require('@/base/assets/images/media/icon_video_play.png')"
              fit="cover"></el-image>
          </div>
        </div>
        <div class="poster-text">
          <p class="text-bold">祝福语接收人：</p>
          <p class="middle-text" v-if="Form.channel_poster_config.content">
            <span v-html="'\u2003\u2003'"></span>{{Form.channel_poster_config.content}}
          </p>
          <el-image v-else class="default-content-img"
            :src="require('../../assets/images/default-content.png')" fit="cover"></el-image>
          <p class="text-bold" style="text-align: end">——{{Form.channel_poster_config.sign_name || '祝福署名'}}
          </p>
        </div>
      </div>
    </div>
    <FixedActionBar>
      <el-button type="default" size="medium" @click="cancel" :loading="saveLoading">取消
      </el-button>
      <!-- <el-button type="default" size="medium" @click="handlePreview" :loading="saveLoading">预览
      </el-button> -->
      <el-button type="primary" size="medium" @click="formSubmit('Form')" :loading="saveLoading">保存
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { configDetail, saveConfig } from "../../api/template/template-detail";
import { CategoryList } from "../../api/tmpl-category/category-list";
import ImageWall from "../../../common/components/SingleMediaWall.vue";
import DefaultImageSelector from "../../../common/components/DefaultImageSelector.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  components: {
    FixedActionBar,
    ImageWall,
    DefaultImageSelector,
  },
  data() {
    return {
      Form: {
        name: "",
        category_id: "", // 分类 ID
        cover: "", // 封面图
        channel_msg_enable: 1, // 系统消息开启，固定 1
        channel_msg_config: {
          content: "",
        },
        channel_sms_enable: 0, // 短信消息开启，固定 0，先不做
        channel_sms_config: null,
        channel_poster_enable: 1, // 海报弹窗开启
        channel_poster_config: {
          type: "image", // image、video
          background:
            window.serverConfig.VUE_APP_ADMINURL +
            "/blessing/images/admin/blessing.png", // 默认图时传空串
          res_image:
            window.serverConfig.VUE_APP_ADMINURL +
            "/blessing/images/admin/blessing2.png",
          res_video: {},
          content: "",
          sign_name: "",
        },
      },
      rules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        category_id: [
          { required: true, message: "请选择模板分类", trigger: "change" },
        ],
        cover: [{ required: true, message: "请上传封面图", trigger: "change" }],
        channel_msg_config: [
          {
            required: true,
            validator: this.passSystemMsg,
            trigger: "change",
          },
        ],
        channel_poster_config: [
          {
            required: true,
            validator: this.passPosterConfig,
            trigger: "change",
          },
        ],
      },
      systemMsgRules: {
        content: [
          { required: true, message: "请输入系统消息", trigger: "blur" },
        ],
      },
      posterRules: {
        content: [{ required: true, message: "请输入祝福语", trigger: "blur" }],
      },
      DefaultBackground:
        window.serverConfig.VUE_APP_ADMINURL +
        "/blessing/images/admin/blessing.png", // 海报祝福背景图
      DefaultPoster:
        window.serverConfig.VUE_APP_ADMINURL +
        "/blessing/images/admin/blessing2.png",
      categoryArr: [],
      formLoading: false,
      saveLoading: false,
    };
  },
  computed: {
    res_media() {
      return (
        this.Form.channel_poster_config.res_video.thumbnail ||
        this.Form.channel_poster_config.res_image
      );
    },
  },
  methods: {
    // 获取详情
    getConfigDetail() {
      this.formLoading = true;
      configDetail({ id: this.$route.params.id })
        .then((res) => {
          const { data } = res;
          this.Form = data;
          this.formLoading = false;
        })
        .catch((err) => {
          this.formLoading = false;
        });
    },
    // 获取分类选择列表
    getCategoryList() {
      CategoryList({ exclude_id: "" })
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    // 验证系统消息
    passSystemMsg(rule, value, callback) {
      if (value) {
        this.$refs.systemMsg.validate((valid) => {
          if (valid) {
            callback();
          } else {
            this.saveLoading = false;
          }
        });
      } else {
        callback();
      }
    },
    // 验证海报设置
    passPosterConfig(rule, value, callback) {
      if (value) {
        this.$refs.posterConfig.validate((valid) => {
          if (valid) {
            callback();
          } else {
            this.saveLoading = false;
          }
        });
      } else {
        callback();
      }
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.Form, id: this.$route.params.id };
          this.saveLoading = true;
          saveConfig(data)
            .then((res) => {
              this.$message.success(res.msg);
              if (this.$route.params.id == 0) {
                this.$router.push({
                  name: "BlessingTmplList",
                });
              } else {
                this.getConfigDetail();
              }

              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    // 预览
    handlePreview() {},
    // 取消
    cancel() {
      this.$router.push({
        name: "BlessingTmplList",
      });
    },
  },
  created() {
    if (this.$route.params.id != 0) {
      this.getConfigDetail();
    }
    this.getCategoryList();
  },
  //调整title
  beforeRouteEnter(to, from, next) {
    const type = to.params.type;
    switch (type) {
      case "add":
        to.meta.title = "新增";
        break;
      // case "view":
      //   to.meta.title = "查看";
      //   break;
      case "edit":
        to.meta.title = "编辑";
        break;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.modular-content {
  margin-left: 11px;
}
.nest-form {
  .el-form-item {
    margin-bottom: 22px;

    &--medium:last-child {
      margin-bottom: 0px;
    }
  }
}
.brand-content {
  ::v-deep.el-image__error,
  .el-image__placeholder {
    background-color: transparent;
  }
  position: relative;
  .add-host:hover {
    border-color: #409eff;
  }

  .input-imgs {
    display: flex;
  }

  .footer-btn {
    @include fixed-card-bottom();
    padding-left: 250px;
  }

  .input-item {
    width: 194px;
    text-align: center;
    margin-right: 100px;

    .images {
      width: 194px;
      height: 135px;
      cursor: pointer;
    }

    .upload-btn {
      width: 194px;
      height: 135px;
      border: 1px dashed #d9d9d9;
      .plus-icon {
        font-size: 20px;
        .upload-text {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .info-title {
      font-size: 14px;
      line-height: 30px;
    }

    .img-tips {
      margin-top: 8px;
      font-size: 12px;
      color: #c0c4cc;
      line-height: 15px;
    }
    .upload-btn:hover {
      border-color: #409eff;
    }
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
.img-tips {
  margin-top: 8px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 15px;
}
.poster-preview {
  position: absolute;
  left: 530px;
  top: 580px;
  .potser-bg {
    width: 300px;
    height: 540px;
  }
  .preview-title {
    margin-top: 20px;
    font-size: 18px;
    color: #353535;
    line-height: 18px;
    width: 100%;
    text-align: center;
  }
  .poster-content {
    position: absolute;
    top: 65px;
    left: 50%;
    margin-left: -133px;
    width: 266px;
    height: 410px;
    border-radius: 4px;
    background-color: #fff;
  }
  .poster-media {
    position: relative;
    margin: 17px 17px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 232px;
    height: 130px;
    background-color: #f3f5f7;
    .media-bg {
      width: 100%;
      height: 100%;
    }
    .video-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      .video-play {
        width: 40px;
        height: 40px;
      }
    }
  }
  .poster-text {
    margin: 30px 17px;
    .default-content-img {
      width: 100%;
      height: 139px;
      margin: 8px 0;
    }
    .middle-text {
      overflow-wrap: break-word;
      word-break: break-all;
      height: 139px;
      margin: 8px 0;
      color: rgba(102, 102, 102, 1);
      font-size: 11px;
      line-height: 18px;
    }
    .text-bold {
      color: rgba(25, 30, 41, 1);
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.inner-form {
  margin-left: 70px;
}
</style>
